export default class Time {
  static sleep(ms: number) {
    return new Promise(resolve => {
      setTimeout(resolve, ms)
    })
  }

  static delayFromLatestEvent(timeoutId: number, cb: () => void, ms: number) {
    clearTimeout(timeoutId)
    return window.setTimeout(() => {
      cb()
    }, ms)
  }

  static debounce<T>(cb: (value: T) => any, ms = 500): (value: T) => void {
    let timeoutId: ReturnType<typeof setTimeout> | number = 0
    return value => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        cb(value)
      }, ms)
    }
  }
}
